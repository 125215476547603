import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    { 
      path: '*', 
      redirect: '/404' 
    }, 
    {
      path: "/bad",
      name: "bad",
      component: () => import("./views/Bad.vue"),
    },        
    {
      path: "/404",
      alias: '/:_id/404',      
      name: "404",
      component: () => import("./views/404.vue"),
    }, 
    {
      path: '/status/:_sub_id/:_key',
      alias: '/:_id/status/:_sub_id/:_key',
      name: 'status',
      component: () => import("./views/Status.vue"),
    },    
    {
      path: "/",
      alias: '/:_id',
      name: "home",
      component: () => import("./views/Home.vue"),
    }
  ],
});

export default router;

router.beforeEach(async (to, from, next) => {

  await Vue.nextTick()
  let data = router.app.configOp.datas
  if(router.app.configOp.datas) {

    if(data.tracking && localStorage.cookies) {
      if(to.name == undefined) {  var name = 'home' } else {  var name = to.name }
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', data.tracking, {
        'send_page_view': false
      });
      gtag('event', 'page_view', {
        page_title: name,
        page_location: window.location.href,
        page_path: to.path,
      })
    }

  if(data.local_link != '') {
    console.log("DEV");
    next();

  } else {
    console.log("PROD");

    /* FORCE HTTPS */
    if (location.protocol !== "https:") {
     // window.location =
     //   "https://" +
     //   window.location.hostname +
     //   window.location.pathname +
     //   window.location.search;
    }
     
  }}

  if(!router.app.configOp.datas && to.name != 'bad') {
    return next("/bad/");
    // next();
  } else {
    next();
  }

});
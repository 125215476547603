import axios from "axios";
import langs from "../json/langs.json";

function setToprod(_id) {
  return axios.get(process.env.VUE_APP_ROOT_API+"/api/formdata/update_env/" + _id, { withCredentials: true });
}

function getDataFromHost(host) {
  return axios.get(process.env.VUE_APP_ROOT_API+"/api/formdata/host/" + host, { withCredentials: true });
}

function getDataFromID(_id) {
  return axios.get(process.env.VUE_APP_ROOT_API+"/api/formdata/" + _id, { withCredentials: true });
}

function getCounterValue(id, name) {
  return axios.get(process.env.VUE_APP_ROOT_API+"/api/v2/counters/" + id + "/" + name, { withCredentials: true });
}

function hexToRgbA(hex, trans) {
  hex = formatToHex(hex)
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      ", " +
      trans +
      ")"
    );
  }
}

function formatToHex(hex) {
  if(hex) {
    var new_hex = hex.replace('#', '');
    return '#'+new_hex
  }
}

export async function Init() {

   try {
    let path = window.location.pathname.split("/");

    if (path[1].length === 24 && path.length >= 2) {

      /* ONT DETECTE UN ID MONGO PASSER DANS L'URL */
      var { data } = await getDataFromID(path[1]);
      var local_link = "/" + path[1]; // ONT AJOUTE L'ID MONGO DANS LES LIENS DU SITE

      console.log('PATH : ',path)

    } else {
  
      /* SINON ONT VA CHERCHER L'ID MONGO EN FONCTION DE HOSTNAME */
      var host = window.location.hostname;
      host = host.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split("/")[0];
      var { data } = await getDataFromHost(host);
      var local_link = "";

      console.log('HOST : ',host)
    }

    /* ONT CONTROLE SI C'EST LA PAGE STATUS QUI EST APPELEE */
    var url = new URL(window.location.href);
    var sub_id = url.searchParams.get("status"); // ID DE SUBMISSION
    var sub_key = url.searchParams.get("key"); // PASSWORD

    /* ONT VERIFIE SI LE MINISITE A BIEN ETAIT CUSTOMISER */
    if (data.custom) {
      var mb = data.custom;
    } else {
      var mb = {};
      data.custom = {};
    }

    mb.ipUniq = data.ipUniq
    if(process.env.VUE_APP_ROOT_API) {
      mb.serverUrl = process.env.VUE_APP_ROOT_API
    } else {
      mb.serverUrl = 'https://api.odr.promo.dev'
    }
    
    if (data.lots) mb.lots = data.lots;
    if(data.redirection_after_valid) mb.redirection_after_valid = data.redirection_after_valid

    /* ONT SET LES VARIABLES  */
    mb.env = data.env;
    mb.local_link = local_link;
    mb.template = "gabarit1";
    mb.operation_id = data._id;
    mb.lang = data.lang;
 
  /* CHOIX DE LA LANGUE */
   if(data.pays.length > 0) {
       var langText = langs.filter((lg) => {
          return lg.lang === data.pays[0].name;
       });
       
       if(langText.length===0) { 
         langText = langs.filter((lg) => {
         return lg.lang === 'fr';
       });
     }
   } else {
    var langText = langs.filter((lg) => {
       return lg.lang === 'fr';
     });
   }
   mb.langText = langText[0].texts

     
   if (data.animation) mb.animation = data.animation;
   if (data.mentionsLegales) mb.mentions = data.mentionsLegales; // MENTIONS
   if (data.contactezNous) mb.contact = data.contactezNous; // CONTACTEZ NOUS

    if (!mb.text_wait)
      mb.text_wait =mb.langText.noStartText;
    if (!mb.text_finish)
      mb.text_finish =mb.langText.finishText;
    if (!mb.text_off)
      mb.text_off =mb.langText.textOff;    
    if (!mb.text_quotafull)
      mb.text_quotafull =mb.langText.limitText;
    if (!mb.text_submited)
      mb.text_submited =mb.langText.thankDescription;
    if (!mb.home_btn_txt) mb.home_btn_txt = mb.langText.participeButton;

    /* GESTION DES DATES ET STATUS */
    var dateBegin = new Date(data.date_debut);
    var dateOff = new Date(data.date_fin);
    var today = new Date();

    if (
      location.hostname != "lucien-mb.netlify.app" &&
      location.hostname != "localhost" &&
      location.hostname != "staging.promo.dev" &&
      location.hostname != "test.promo.dev"
    ) {


      if (!data.available) {
       mb.participation = "offline";
      }       
      else if (dateOff > today && dateBegin > today) {
        mb.participation = "wait";
      } else if (dateOff < today) {
        mb.participation = "finish";
      } else {
        mb.participation = "open";
      }

      if(mb.participation==="open"){
        // let setToProd = await setToprod(data._id)
        // mb.participation = "offline";
      }

    } else {
      mb.participation = "open";
    }

    console.log('STATUT OP : ', mb.participation)

    if(location.hostname === "localhost" || location.hostname === "test.promo.dev" || location.hostname === "lucien-mb-staging.netlify.app") {
      mb.toolbar = true;
    } 



    if (!data.type) {
      mb.type = "ODR";
    } else {
      mb.type = data.type;
    }

    // mb.animation = data.animation

    /* GESTION DU COMPTEUR */

    if (data.counters && data.counters[0]) {
      mb.counter = data.counters[0];
      let datacounter = await getCounterValue(data._id, data.counters[0].name);


      if(datacounter.data.increment) {

        mb.counter.total = parseInt(datacounter.data.limit);
        mb.counter.restant = parseInt(datacounter.data.limit);
      } else {
        mb.counter.total = parseInt(datacounter.data.value);
        mb.counter.restant = parseInt(datacounter.data.value);
      }



      mb.counter.percent = Math.floor(
        (parseInt(datacounter.data.value) / parseInt(mb.counter.limit)) * 100
      );
      if (mb.compteur) mb.counter.display = mb.compteur; // COMPTEUR CUSTOM
      if (mb.counter.restant < 1) mb.participation = "overquota";

      if (mb.counter.type === "INFORMATIVE") {
        if (data.custom.compteur_limit_visible) {
          if (
            data.custom.compteur_limit_visible > 0 &&
            mb.counter.total < data.custom.compteur_limit_visible
          ) {
            mb.counter.display = "hidden";
          }
        }
      } else {
        if (data.custom.compteur_limit_visible) {
          if (
            data.custom.compteur_limit_visible > 0 &&
            mb.counter.restant > data.custom.compteur_limit_visible
          ) {
            mb.counter.display = "hidden";
          }
        }
      }
    } else {
      mb.counter = {};
    }

    /* SI PAS DE TITRE CUSTOM ON AFFICHE LE NOM DE L'OP */
    if (mb.titre_home) {
      var site_title = mb.titre_home;
    } else {
      var site_title = data.title;
    }

    if (!mb.pagetitle) {
      mb.pagetitle = site_title;
    }

    if (!mb.compteur_text_after) {
      mb.compteur_text_after = "participations";
    }

    mb.title = site_title; // TITRE DE L'OP
    window.document.title = mb.pagetitle.replace(/(<([^>]+)>)/gi, ""); // BALISE TITLE ONT SHOOT LE HTML
    mb.date_debut = data.date_debut; // DATE DE DEBUT
    mb.date_fin = data.date_fin; // BATE DE FIN
    mb.date_fin_achat = data.date_fin_achat;
    mb.tracking = data.tracking; // CLE GOOGLE ANALYTICS

    /* GESTION DES FORMULAIRES */
    mb.step = data.forms.length + 1;
    var status = data.forms.filter(function(status) {
      return status.type === "UPDATE";
    });

    var create = data.forms.filter(function(create) {
      return create.type === "CREATE";
    });

    if (status[0]) mb.statusID = status[0]._id; // ONT PREND LE PREMIER
    if (create[0]) mb.createID = create[0]._id;
    if (sub_id && sub_key) {
      mb.statuspage = true;
      mb.text_home_button = "Mon status";
    }

    /* GESTION DES DOCUMENTS DIGITAL OCEAN */
    if (!data.files) {
      data.files = [];
    }

    var fl_politique = data.files.filter((s) =>
      s.filename.includes("politique")
    );
    if (fl_politique && fl_politique.length > 0) {
      mb.politique_uri = fl_politique[0].uri
    }
    var fl_modalites = data.files.filter((s) =>
      s.filename.includes("modalites")
    );
    if (fl_modalites && fl_modalites.length > 0) {
      mb.modalites_uri = fl_modalites[0].uri
    }
    var fl_reglement = data.files.filter((s) =>
      s.filename.includes("reglement")
    );
    if (fl_reglement && fl_reglement.length > 0) {
      mb.reglement_uri = fl_reglement[0].uri
    }


    var timestamp = Math.round(new Date().getTime() / 1000);

    /* LOT PERDU */
    var lot_perdu = data.files.filter((s) => s.filename.includes("lot_perdu"));
    if (lot_perdu && lot_perdu.length > 0) {
      mb.image_lot_perdu = lot_perdu[0].uri;
    }

    /* GESTION DU FAVICON */
    var favicon = data.files.filter((s) => s.filename.includes("favicon"));
    if (favicon && favicon.length > 0) {
      var link = document.querySelector("link[rel='icon']");
      link.href = favicon[0].uri + "?" + timestamp;
    }

    /* GESTION DU LOGO */
    mb.logo = "/logo1.png";
    var logo = data.files.filter((s) => s.filename.includes("logo1"));
    if (logo && logo.length > 0) {
      mb.logo = logo[0].uri;
    }

    /* GESTION DE LA BANNIERE */
    mb.banner_link = "/banner_mb.jpg";
    var banner = data.files.filter((s) => s.filename.includes("banner_mb"));
    if (banner && banner.length > 0) {
      mb.banner_link = banner[0].uri;
      mb.banner_type = "photo";
    }

    var n = 1;
    mb.banner_steps = [];
    while (n < 6) {
      mb.banner_steps[n] = data.files.filter((s) => s.filename.includes("banner_mb_step"+n));
      n++;
    }
    // mb.banner_disable_animation = true

    /* GESTION DU BACKGROUND */
    var bck_banner = data.files.filter((s) =>
      s.filename.includes("background_mb")
    );
    if (bck_banner && bck_banner.length > 0) {
      mb.background_image_link = bck_banner[0].uri;
    }

    /* GESTION GOOGLE FONTS */
    var fontID = "Roboto";
    if (mb.font_family) fontID = mb.font_family;

    if (
      fontID != "AmpleSoft Pro" &&
      fontID != "Handelson Four" &&
      fontID != "MSans" &&
      fontID != "Museo Slab" &&
      fontID != "Qanelas" &&
      fontID != "SantElia Script" &&
      fontID != "Blackore" &&
      fontID !="Argone" &&
      fontID !="Impact" &&
      fontID !="Montserrat"
    ) {
      var ggFont=fontID
      
    } else {
     var ggFont="Roboto" 
    }

          // SI LA FONT N'EST PAS LOCALE
          var head = document.getElementsByTagName("head")[0];
          var link = document.createElement("link");
          link.id = ggFont;
          link.rel = "stylesheet";
          link.type = "text/css";
          link.href =
            "https://fonts.googleapis.com/css?family=" +
            ggFont +
            ":wght@300;400;500;700;900&display=swap";
          link.media = "all";
          head.appendChild(link);

    if (!mb.mobile_menu_position) {
      mb.mobile_menu_position = "left";
    } else {
      mb.mobile_menu_position = mb.mobile_menu_position;
    }
  
    /* GESTION DES CODES COULEURS + FONTS */
    if (!mb.font_size) mb.font_size = 16;
    if (!mb.border_radius_popup) mb.border_radius_popup = 0;
    if (!mb.border_radius_field) mb.border_radius_field = 0;
    if (!mb.border_radius_buttons) mb.border_radius_buttons = 0;
    if (!mb.primary_color_bg) mb.primary_color_bg = "#FFFFFF";
    if (!mb.body_background_color) mb.body_background_color = undefined;
    if (!mb.primary_color_txt) mb.primary_color_txt = "#000000";
    if (!mb.secondary_color_bg) mb.secondary_color_bg = "#EC520D";
    if (!mb.secondary_color_txt) mb.secondary_color_txt = "#FFFFFF";
    if (!mb.color_top_link) mb.color_top_link = "#FFFFFF";
    if (!mb.color_input_border) mb.color_input_border = "#ACC4D9";
    if (!mb.color_input_bg) mb.color_input_bg = "#FFFFFF";
    if (!mb.color_input_txt) mb.color_input_txt = "#000000";
    if (!mb.color_input_bg_focus) mb.color_input_bg_focus = "#F2F7F9";
    if (!mb.input_txt_error) mb.input_txt_error = "#F12137";
    if (!mb.color_input_bg_error) mb.color_input_bg_error = "#F6E5E9";
    if (!mb.color_bg_button_back) mb.color_bg_button_back = "#DCDCDC";
    if (!mb.color_text_button_back) mb.color_text_button_back = "#444444";
    if (!mb.color_bg_valid_check) mb.color_bg_valid_check = "#4bb71b";
    if (!mb.color_top_bg_link) mb.color_top_bg_link = "#FFFFFF";

    var color_bg_top = hexToRgbA(mb.color_top_bg_link, "0.35");

    if (mb.transparence_nav_link && mb.transparence_nav_link > 0) {
      var color_bg_top = hexToRgbA(
        mb.color_top_bg_link,
        mb.transparence_nav_link / 100
      );
    } else {
      var color_bg_top = hexToRgbA(mb.color_top_bg_link, "0.35");
    }

    const style = document.createElement("style");
    style.appendChild(
      document.createTextNode(
        `:root {
            --body-font-size : ${mb.font_size}px;
            --body-fonts: "${fontID}", "Arial", sans-serif; 
            --primary-color-bg : ${formatToHex(mb.primary_color_bg)};
            --primary-color-txt : ${formatToHex(mb.primary_color_txt)}; 
            --secondary-color : ${formatToHex(mb.secondary_color_bg)}; 
            --secondary-color-txt : ${formatToHex(mb.secondary_color_txt)};    
            --color-top-bg-link : ${color_bg_top}; 
            --dark-mode: rgba(var(--orange), 0.15);            
            --color-top-link : ${formatToHex(mb.color_top_link)}; 
            --color-input-border :${formatToHex(mb.color_input_border)};
            --color-input-bg :${formatToHex(mb.color_input_bg)};           
            --color-input-txt :${formatToHex(mb.color_input_txt)}; 
            --color-input-bg-focus :${formatToHex(mb.color_input_bg_focus)};
            --color-input-txt-error: ${formatToHex(mb.input_txt_error)};
            --color-input-bg-error:${formatToHex(mb.color_input_bg_error)};     
            --color-error-txt:${formatToHex(mb.input_txt_error)};            
            --border-radius-popup : ${mb.border_radius_popup}px; 
            --border-radius-button : ${mb.border_radius_buttons}px; 
            --border-radius-field : ${mb.border_radius_field}px;             
            --color-bg-button-back : ${formatToHex(mb.color_bg_button_back)}; 
            --color-text-button-back : ${formatToHex(mb.color_text_button_back)}; 
            --color-bg-valid-check : ${formatToHex(mb.color_bg_valid_check)};               
        }`
      )
    );
    document.head.appendChild(style);
    return mb;
  } catch (e) {
    console.log(e);
  }
}

<template>
  <header>
    <svg

      v-if="currentRouteName !='status'"
      v-bind:class="{ isHome : current_page ==='home' }"
      @click="toggle"

      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 384 384"
      style="enable-background:new 0 0 255 255;display:none;"
      xml:space="preserve"
    >
      <g>
        <g>
          <g>
            <rect x="0" y="277.333" width="384" height="42.667" />
            <rect
              x="0"
              y="170.667"
              width="384"
              fill="param(color)"
              height="42.667"
            />
            <rect
              x="0"
              y="64"
              width="384"
              fill="param(color)"
              height="42.667"
            />
          </g>
        </g>
      </g>
      <g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>

    <Drawer @close="toggle" align="right" :closeable="true">
      <div v-if="open">
         <a class="mobile" @click="openMobilePage('home')"><img class="logo" :src="`${logo}`" /></a>   
         <img alt="logo" class="logo desktop" :src="`${logo}`" />
        <ul >
          <li v-if="participation === 'open'">
              <a class="mobile" @click="openMobilePage('form')">{{ datas.home_btn_txt }}</a>   
              <a class="desktop" @click="openMiddlePage('form')">{{ datas.home_btn_txt }}</a>               
          </li>
          <li v-for="item in datas.links" :key="item.message">
             <a :href="`${item.link}`" rel="noopener" :target="`${item.target}`">{{ item.label }}</a>
           </li>      
          <li>
              <a rel="noopener" 
                v-if="datas.reglement_uri"
                :href="`${link.uri}`"
                target="_blank"
                >{{ data.langText.reglementLink }}</a
              >
              <a rel="noopener" 
                v-if="datas.modalites_uri"
                :href="`${link.uri}`"
                target="_blank"
                >{{ data.langText.modalitesLink }}</a
              >
              <a rel="noopener" 
                v-if="datas.politique_uri"
                :href="`${link.uri}`"
                target="_blank"
                >{{ data.langText.confidentialiteLink }}</a
              >
            </li>
            <li v-if="mentions">
              <a class="mobile" @click="openMobilePage('mentions')">{{ data.langText.mentionsLink }}</a>   
              <a class="desktop" @click="openMiddlePage('mentions')">{{ data.langText.mentionsLink }}</a>                           
            </li>
            <li v-if="contact">
              <a class="mobile" @click="openMobilePage('contact')">{{ data.langText.contactLink }}</a>       
              <a class="desktop" @click="openMiddlePage('contact')">{{ data.langText.contactLink }}</a>                       
            </li>
            <li class="mobile_social_links" v-if="datas.facebook_link || datas.twitter_link || datas.instagram_link || datas.youtube_link || datas.snapchat_link || datas.discord_link || datas.tiktok_link">
            <ul >
              <li>
                <li v-if="datas.facebook_link"><a :href="`${datas.facebook_link}`" rel="noopener" target="_blank"><i class="fab fa-facebook-square"></i></a></li> 
                <li v-if="datas.twitter_link"><a :href="`${datas.twitter_link}`" rel="noopener" target="_blank"><i class="fab fa-twitter"></i></a></li> 
                <li v-if="datas.instagram_link"><a :href="`${datas.instagram_link}`" rel="noopener" target="_blank"><i class="fab fa-instagram"></i></a></li> 
                <li v-if="datas.youtube_link"><a :href="`${datas.youtube_link}`" rel="noopener" target="_blank"><i class="fab fa-youtube"></i></a></li>
                <li v-if="datas.snapchat_link"><a :href="`${datas.snapchat_link}`" rel="noopener" target="_blank"><i class="fab fa-snapchat-ghost"></i></a></li>  
                <li v-if="datas.pinterest_link"><a :href="`${datas.pinterest_link}`" rel="noopener" target="_blank"><i class="fab fa-pinterest-square"></i></a></li>  
                <li v-if="datas.linkedin_link"><a :href="`${datas.linkedin_link}`" rel="noopener" target="_blank"><i class="fab fa-linkedin"></i></a></li>        
                <li v-if="datas.discord_link"><a :href="`${datas.discord_link}`" rel="noopener" target="_blank"><i class="fab fa-discord"></i></a></li>   
                <li v-if="datas.tiktok_link"><a :href="`${datas.tiktok_link}`" rel="noopener" target="_blank"><i class="fa-brands fa-tiktok"></i></a></li>                                                                                                 
              </ul>

            </li>
        </ul>
      </div>
    </Drawer>
  </header>
</template>

<script>
import Vue from 'vue';
import EventBus from '@/event-bus';
import Drawer from "vue-simple-drawer";

export default {
  name: "Header",
  data: function() {
    return {
      datas : this.$root.configOp.datas,
      current_page: 'home',
      page_name : this.$route.name,
      participation: this.$root.configOp.datas.participation,
      contact: this.$root.configOp.datas.contact,
      mentions: this.$root.configOp.datas.mentions,   
      open: false,
      logo: this.$root.configOp.datas.logo,
      navLinks: this.$root.configOp.datas.navLinks,
      local_link: this.$root.configOp.datas.local_link,
    };
  },
  components: {
    Drawer,
  },
    mounted () {
      console.log(this.datas.tiktok_link)
    EventBus.$on('setcurrent', (value) => {
        this.current_page = value
    });
  },
 computed: {
    currentRouteName() {
        return this.$route.name;
    }
}, 
  methods: {
    toggle() {
      this.open = !this.open;
    },
    openMiddlePage : function(name) {
      this.openMobilePage(name)
      document.getElementById("banner").style.display = "block"; 
      document.getElementById("home").style.display = "flex";
    },
    openMobilePage : function(name) {
        this.current_page = name;
        if(document.getElementById('done') !== null) document.getElementById("done").style.display = "none";
        if(document.getElementById('home') !== null) document.getElementById("home").style.display = "none";
        if(document.getElementById('form') !== null) document.getElementById("form").style.display = "none";
        if(document.getElementById('mentions') !== null) document.getElementById("mentions").style.display = "none";
        if(document.getElementById('contact') !== null) document.getElementById("contact").style.display = "none"; 
        if(name=="home") {
           document.getElementById(name).style.display = "flex";
        } else {
          document.getElementById(name).style.display = "block";
        }     
        if(name =='home') {
            document.getElementById("banner").style.display = "block"; 
        } else {
            document.getElementById("banner").style.display = "none"; 
        }
        this.toggle()
    },    
  },
};
</script>
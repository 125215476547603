<template>
  <div id="app">
    <!-- <div class="nav-mob">Menu</div>  -->
     <div v-if="good_id && datas.template==='gabarit1'" :class="`${background_class}`" :style="`background-image:url(${background_link})`"></div>
     <headerElem  v-if="good_id" />
      <transition name="fade" mode="out-in">
        <router-view v-bind:toolbar="toolbar" :mycurrent="setcurrent"/>
      </transition>
    <FooterElem v-if="good_id" />
 </div> 
</template>

<script>
import HeaderElem from '@/components/Header'
import FooterElem from '@/components/Footer'

export default {
  name: 'App',
  data: function() {
    return {
      good_id : false,
      datas : this.$root.configOp.datas,
      background_link : undefined,
      toolbar : { toto : 1 }
    };
  },   
  components: {
    HeaderElem,
    FooterElem,
  },
  mounted() {
    if(this.$root.configOp.datas) {
      this.good_id = true
      if(this.datas.background_image_link) { // si background image
        this.background_link = this.datas.background_image_link
        this.background_class = 'background-body'
      } else if(this.datas.body_background_color) { // si background color
        document.body.style.backgroundColor = this.datas.body_background_color;
        this.background_class = 'background-color'
      } else { // image floutée par defaut
        this.background_link = this.datas.banner_link
        this.background_class = 'background-image'
      }
    }
  },
  methods: {
        setcurrent(value) {

        }
  }
}
</script>
